import React from 'react';
import Layout from '../layouts/page-layout';
import SEO from '../utils/seo';
import PageHero from '../containers/pageHeroContainer';

const About = () => (
  <Layout fixed={true}>
    <SEO
      title="Home"
      description="Unitech Pest and Bed Bug Services is a full service, professional pest management company located in St. Louis, Missouri.  We stand prepared to eliminate unwanted pests in your home or business."
    />

    <PageHero />
  </Layout>
);

export default About;
