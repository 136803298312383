import React from 'react';
import { AboutHeroSection } from '../components';
import AboutPageInformation from './aboutPageInformation';

const PageHero = () => {
  return (
    <>
      <AboutHeroSection padding="13rem 0 0">
        <AboutHeroSection.Title>
          Welcome to Unitech Pest & Termite Control in St. Louis, MO
        </AboutHeroSection.Title>

        <svg
          style={{ transform: 'translateY(10px)' }}
          viewBox="0 -20 1440 124"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 64.7626L60 69.0312C120 73.622 240 81.676 360 64.7626C480 47.8492 600 4.3577 720 0.330708C840 -3.69629 960 30.1305 1080 43.2585C1200 55.9032 1320 47.8493 1380 43.2585L1440 38.9899V116.308H1380C1320 116.308 1200 116.308 1080 116.308C960 116.308 840 116.308 720 116.308C600 116.308 480 116.308 360 116.308C240 116.308 120 116.308 60 116.308H0V64.7626Z"
            fill="#6AF0B0"
          />
          <path
            d="M0 72.3111L60 76.5797C120 81.1705 240 89.2245 360 72.3111C480 55.3978 600 11.9062 720 7.87922C840 3.85222 960 37.679 1080 50.807C1200 63.4517 1320 55.3978 1380 50.807L1440 46.5384V123.857H1380C1320 123.857 1200 123.857 1080 123.857C960 123.857 840 123.857 720 123.857C600 123.857 480 123.857 360 123.857C240 123.857 120 123.857 60 123.857H0V72.3111Z"
            fill="#F8F9FA"
          />
        </svg>
      </AboutHeroSection>
      <AboutPageInformation />
    </>
  );
};
export default PageHero;
