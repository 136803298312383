import React from 'react';
import { AboutPageInfo } from '../components';
import { graphql, useStaticQuery } from 'gatsby';
import { AiOutlineHome, AiOutlineRight } from 'react-icons/all';
const AboutPageInformation = () => {
  const data = useStaticQuery(graphql`
    query {
      information: allContentfulAboutPage {
        edges {
          node {
            id
            title
            description {
              description
              id
            }
          }
        }
      }
      signature: file(relativePath: { eq: "signature.png" }) {
        childImageSharp {
          fixed(width: 160, height: 55, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <AboutPageInfo>
      <AboutPageInfo.Container>
        <AboutPageInfo.IconsWrapper>
          <AboutPageInfo.Links to="/" backgroundColor>
            <AiOutlineHome style={{ fontSize: '1.8rem' }} /> Home
          </AboutPageInfo.Links>{' '}
          <AboutPageInfo.Links>
            <AiOutlineRight
              style={{ verticalAlign: 'middle', fontSize: '1.5rem' }}
            />
            About Us
          </AboutPageInfo.Links>
        </AboutPageInfo.IconsWrapper>
        {data.information.edges.map(({ node: { id, title, description } }) => {
          return (
            <AboutPageInfo.Wrapper>
              <AboutPageInfo.Title key={id}>{title}</AboutPageInfo.Title>
              <AboutPageInfo.Description key={description.id}>
                {description.description}
              </AboutPageInfo.Description>
            </AboutPageInfo.Wrapper>
          );
        })}
        <AboutPageInfo.OwnerContainer>
          <AboutPageInfo.Owner>
            Allen Stagner, Owner Unitech Pest and Termite Control
          </AboutPageInfo.Owner>
          <AboutPageInfo.Signature
            fixed={data.signature.childImageSharp.fixed}
            alt="signature"
          />
        </AboutPageInfo.OwnerContainer>
      </AboutPageInfo.Container>
    </AboutPageInfo>
  );
};
export default AboutPageInformation;
